import React, { useState, useContext } from "react"
import SelectField from "./CustomSelectField"
import LongevityRadios from "./CustomLongevityRadios"
import { appContext } from '../../provider'

var initialValuesPoundsNPerCubicYard = {
  low: {
    "2-3-months": 0.6,
    "3-4-months": 0.5,
    "5-6-months": 0.8,
    "8-9-months": 1.3,
    "12-14-months": 1.8,
    "14-16-months": 2.6,
  },
  medium: {
    "2-3-months": 1.0,
    "3-4-months": 1.2,
    "5-6-months": 1.5,
    "8-9-months": 2.0,
    "12-14-months": 2.5,
    "14-16-months": 3.3,
  },
  high: {
    "2-3-months": 1.3,
    "3-4-months": 1.785,
    "5-6-months": 2.2,
    "8-9-months": 2.7,
    "12-14-months": 3.2,
    "14-16-months": 4.0,
  },
}
var initialValuesPoundsNPerCubicYardLandscapeBed = {
  low: {
    "2-3-months": 1.0,
    "3-4-months": 1.0,
    "5-6-months": 2.0,
    "8-9-months": 2.0,
    "12-14-months": 2.0,
    "14-16-months": 2.0,
  },
  medium: {
    "2-3-months": 2.0,
    "3-4-months": 2.0,
    "5-6-months": 3.0,
    "8-9-months": 3.5,
    "12-14-months": 4.0,
    "14-16-months": 4.0,
  },
  high: {
    "2-3-months": 3.0,
    "3-4-months": 3.0,
    "5-6-months": 4.0,
    "8-9-months": 5.0,
    "12-14-months": 6.0,
    "14-16-months": 6.0,
  },
}
function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}
const PageContentFindRates = () => {

  const context = useContext(appContext)

  const [state, setState] = useState({
    nValue: 0,
    applicationEnv: "incorporation",
    longevity: "",
    incorporationRates: {
      poundsPerCubicYard: {
        low: "",
        medium: "",
        high: "",
      },
      kilogramsPerCubicMeter: {
        low: "",
        medium: "",
        high: "",
      },
      gramsPerLiter: {
        low: "",
        medium: "",
        high: "",
      },
    },
    landscapeBedRates: {
      poundsPerThousandSqFt: {
        low: "",
        medium: "",
        high: "",
      },
    },
    greenhouseContainerRates: [
      {
        label: '4" standard (0.88 pint)',
        containersPerCubicYard: 1836,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '4" square (1.25 pint)',
        containersPerCubicYard: 1293,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '5" azalea (1.62 pints)',
        containersPerCubicYard: 998,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '6" azalea (1.5 qt)',
        containersPerCubicYard: 539,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '6" hanging basket (1.5 qt)',
        containersPerCubicYard: 539,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '6" standard (1.75 qt)',
        containersPerCubicYard: 462,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '6.5" azalea (1.8 qt)',
        containersPerCubicYard: 449,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '8" azalea (3 qt)',
        containersPerCubicYard: 269,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '8" hanging basket (3 qt)',
        containersPerCubicYard: 269,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '8" mum pan (1 gal)',
        containersPerCubicYard: 260,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '9" mum pan (1.25 gal)',
        containersPerCubicYard: 166,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '10" hanging basket (1.5 gal)',
        containersPerCubicYard: 150,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '12" color bowl (2 gal)',
        containersPerCubicYard: 112,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '12" hanging basket (2.25 gal)',
        containersPerCubicYard: 100,
        low: "",
        medium: "",
        high: "",
      },
    ],
    nurseryContainerRates: [
      {
        label: "1 quart",
        containersPerCubicYard: 850,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: "2 quart",
        containersPerCubicYard: 400,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: "1 gallon trade",
        containersPerCubicYard: 300,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: "1 gallon",
        containersPerCubicYard: 210,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: "2 gallon trade",
        containersPerCubicYard: 125,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: "2 gallon",
        containersPerCubicYard: 102,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: "3 gallon",
        containersPerCubicYard: 70,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: "5 gallon",
        containersPerCubicYard: 52,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: "7 gallon",
        containersPerCubicYard: 35,
        low: "",
        medium: "",
        high: "",
      },
    ],
    largeContainerRates: [
      {
        label: '10 gallon - 17" diam.',
        surfArea: 1.4,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '15 gallon - 17.5" diam.',
        surfArea: 1.5,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '20 gallon - 21" diam.',
        surfArea: 2.3,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '25 gallon - 22.5" diam.',
        surfArea: 2.8,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '30 gallon - 26.5" diam.',
        surfArea: 3.8,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '45 gallon - 30" diam.',
        surfArea: 4.8,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '65 gallon - 30" diam.',
        surfArea: 4.8,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '100 gallon - 36" diam.',
        surfArea: 7.1,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: '200 gallon - 48.5" diam. ',
        surfArea: 12.8,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: "24 inch box",
        surfArea: 4.0,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: "30 inch box",
        surfArea: 6.25,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: "36 inch box",
        surfArea: 9.0,
        low: "",
        medium: "",
        high: "",
      },
      {
        label: "48 inch box",
        surfArea: 16.0,
        low: "",
        medium: "",
        high: "",
      },
    ],
    landscapeBedContainerRates: [
      {
        label:
          "Other larger containers - use these rates multiplied by actual container surface area in sq. ft.",
        surfArea: 1.0,
        low: "",
        medium: "",
        high: "",
      },
    ]
  })

  const updateNValue = (e) => {
    setState({
      ...state,
      nValue: e.target.value,
    })
  }

  const updateLongevity = (e) => {
    context.updateLongevity(e);
    setState({
      ...state,
      longevity: e,
    })
  }

  const updateApplicationEnv = (e) => {
    setState({
      ...state,
      applicationEnv: e.target.value,
    })
  }

  const calculateRates = (e) => {
    // Prevent default form behavior
    e.preventDefault()

    // Clone the current state and make calculations to the new object
    var obj = state

    console.log('calculateRates: ', obj.longevity, context.longevity)

    // Make changes to the object
    Object.keys(obj.incorporationRates.poundsPerCubicYard).map(level => {
      return (obj.incorporationRates.poundsPerCubicYard[level] = round(
        initialValuesPoundsNPerCubicYard[level][context.longevity] /
          (obj.nValue / 100),
        1
      ))
    })

    Object.keys(obj.incorporationRates.kilogramsPerCubicMeter).map(level => {
      return (obj.incorporationRates.kilogramsPerCubicMeter[level] = round(
        initialValuesPoundsNPerCubicYard[level][context.longevity] /
          (obj.nValue / 100) /
          2.2056 /
          0.7646,
        1
      ))
    })

    Object.keys(obj.incorporationRates.gramsPerLiter).map(level => {
      return (obj.incorporationRates.gramsPerLiter[level] = round(
        ((initialValuesPoundsNPerCubicYard[level][context.longevity] /
          (obj.nValue / 100)) *
          453.5924) /
          764.6,
        1
      ))
    })

    Object.keys(obj.landscapeBedRates.poundsPerThousandSqFt).map(level => {
      return (obj.landscapeBedRates.poundsPerThousandSqFt[level] = round(
        50 /
          ((50 * (obj.nValue / 100)) /
            initialValuesPoundsNPerCubicYardLandscapeBed[level][context.longevity]),
        1
      ))
    })

    obj.greenhouseContainerRates.map(item => {
      item.low = round(
        (obj.incorporationRates.poundsPerCubicYard.low * 454) /
          item.containersPerCubicYard,
        1
      )
      item.medium = round(
        (obj.incorporationRates.poundsPerCubicYard.medium * 454) /
          item.containersPerCubicYard,
        1
      )
      item.high = round(
        (obj.incorporationRates.poundsPerCubicYard.high * 454) /
          item.containersPerCubicYard,
        1
      )
      return item
    })

    obj.nurseryContainerRates.map(item => {
      item.low = round(
        (obj.incorporationRates.poundsPerCubicYard.low * 454) /
          item.containersPerCubicYard,
        1
      )
      item.medium = round(
        (obj.incorporationRates.poundsPerCubicYard.medium * 454) /
          item.containersPerCubicYard,
        1
      )
      item.high = round(
        (obj.incorporationRates.poundsPerCubicYard.high * 454) /
          item.containersPerCubicYard,
        1
      )
      return item
    })

    obj.landscapeBedContainerRates.map(item => {
      item.low = round(
        (((obj.incorporationRates.poundsPerCubicYard.low * 454) / 27) * 8) / 12,
        1
      )
      item.medium = round(
        (((obj.incorporationRates.poundsPerCubicYard.medium * 454) / 27) * 8) /
          12,
        1
      )
      item.high = round(
        (((obj.incorporationRates.poundsPerCubicYard.high * 454) / 27) * 8) /
          12,
        1
      )
      return item
    })

    obj.largeContainerRates.map(item => {
      item.low = round(
        ((((obj.incorporationRates.poundsPerCubicYard.low * 454) / 27) * 8) /
          12) *
          item.surfArea,
        1
      )
      item.medium = round(
        ((((obj.incorporationRates.poundsPerCubicYard.medium * 454) / 27) * 8) /
          12) *
          item.surfArea,
        1
      )
      item.high = round(
        ((((obj.incorporationRates.poundsPerCubicYard.high * 454) / 27) * 8) /
          12) *
          item.surfArea,
        1
      )
      return item
    })

    // Then set the state to the new object
    setState({...state, obj})
  }
    // const { page } = this.props
    return (
      <div className="container">
        <form onSubmit={calculateRates}>
          <label
            htmlFor="findRatesNitrogen"
            className="mb-2 font-weight-bold text-secondary"
          >
            Select NPK ratio{" "}
          </label>
          <div className="form-row">
            <div className="col-4">
              <SelectField
                name="findRatesNitrogen"
                label="N"
                required
                // onHandleChange={calculateRates}
                onSelectChange={updateNValue}
              >
                <option value="">N</option>
                <option value="9">9</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="27">27</option>
              </SelectField>
            </div>
            <div className="col-4">
              <SelectField name="findRatesPhosphorus" label="P">
                <option value="">P</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="13">13</option>
                <option value="14">14</option>
              </SelectField>
            </div>
            <div className="col-4">
              <SelectField name="findRatesPotassium" label="K">
                <option value="">K</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="18">18</option>
              </SelectField>
            </div>
          </div>
          <LongevityRadios onHandleRadioChange={(e) => { updateLongevity(e) }} />
          <SelectField
	          label="Select application/environment"
	          name="productApplicationEnv"
	          value={state.applicationEnv}
            onSelectChange={updateApplicationEnv}
            labelAboveField
	        >
	          <option value="incorporation">Incorporation</option>
	          <option value="landscape-bed">Landscape bed</option>
	          <option value="greenhouse">Greenhouse container (topdress)</option>
	          <option value="nursery">Nursery container (topdress)</option>
	          <option value="large-topdress">Large container (topdress)</option>
	          <option value="large-landscape">Large container (landscape bed)</option>
	        </SelectField>
          <button type="submit" className={`btn btn-icl btn-skew btn-block${!state.nValue || !context.longevity ? ' disabled' : ''}`}>
            <span>Search</span>
          </button>
        </form>
        <hr />
        {/*state.applicationEnv !== null && <h2>Results</h2>*/}
        

      <div className="table-responsive-wrapper">
        {state.applicationEnv === "incorporation" && (
          <>
            <h2 className="font-weight-normal text-icl">Recommended incorporation rates</h2>
            <div className="table-responsive">
              <table className="table table__no-overflow">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Low</th>
                    <th>Medium</th>
                    <th>High</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(state.incorporationRates).map((item, i) => {
                    let uncamelcased = item.replace(/([A-Z])/g, " $1")
                    let th =
                      uncamelcased.charAt(0).toUpperCase() +
                      uncamelcased.slice(1).toLowerCase()
                    return (
                      <tr key={i}>
                        <td>{th}</td>
                        {Object.keys(state.incorporationRates[item]).map(
                          td => (
                            <td key={td}>
                              {state.incorporationRates[item][td]}
                            </td>
                          )
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div className="table-fade"></div>
            </div>
          </>
        )}
        {state.applicationEnv === "landscape-bed" && (
          <>
            <h2 className="font-weight-normal text-icl">Recommended landscape bed rates</h2>
            <div className="table-responsive">
              <table className="table table__no-overflow">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Clay</th>
                    <th>Loam</th>
                    <th>Sand</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(state.landscapeBedRates).map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>Pounds per 1,000 sq. ft.</td>
                        {Object.keys(state.landscapeBedRates[item]).map(
                          td => (
                            <td key={td}>
                              {state.landscapeBedRates[item][td]}
                            </td>
                          )
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div className="table-fade"></div>
            </div>
          </>
        )}
        {state.applicationEnv === "greenhouse" && (
	      <>
	      <h2 className="font-weight-normal text-icl">Recommended greenhouse container (topdress) rates</h2>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Greenhouse</th>
                  <th>Containers per cubic yard (approx.)</th>
                  <th>Low rate (grams)</th>
                  <th>Medium rate (grams)</th>
                  <th>High rate (grams)</th>
                </tr>
              </thead>
              <tbody>
                {state.greenhouseContainerRates.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.label}</td>
                      <td>{item.containersPerCubicYard}</td>
                      <td>{item.low}</td>
                      <td>{item.medium}</td>
                      <td>{item.high}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="table-fade"></div>
          </div>
         </>
        )}
        {state.applicationEnv === "nursery" && (
	      <>
	      <h2 className="font-weight-normal text-icl">Recommended nursery container (topdress) rates</h2>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Nursery</th>
                  <th>Containers per cubic yard (approx.)</th>
                  <th>Low rate (grams)</th>
                  <th>Medium rate (grams)</th>
                  <th>High rate (grams)</th>
                </tr>
              </thead>
              <tbody>
                {state.nurseryContainerRates.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.label}</td>
                      <td>{item.containersPerCubicYard}</td>
                      <td>{item.low}</td>
                      <td>{item.medium}</td>
                      <td>{item.high}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="table-fade"></div>
          </div>
		  </>
        )}
        {state.applicationEnv === "large-topdress" && (
	      <>
	      <h2 className="font-weight-normal text-icl">Recommended large container (topdress) rates</h2>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Large containers</th>
                  <th>Surf. area (sq. ft.)</th>
                  <th>Low rate (grams)</th>
                  <th>Medium rate (grams)</th>
                  <th>High rate (grams)</th>
                </tr>
              </thead>
              <tbody>
                {state.largeContainerRates.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.label}</td>
                      <td>{item.surfArea}</td>
                      <td>{item.low}</td>
                      <td>{item.medium}</td>
                      <td>{item.high}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="table-fade"></div>
          </div>
         </>
        )}
        {state.applicationEnv === "large-landscape" && (
	      <>
	      <h2 className="font-weight-normal text-icl">Recommended large container (landscape bed) rates</h2>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Large container landscape bed (rates per square foot)</th>
                  <th>Surf. area (sq. ft.)</th>
                  <th>Low rate (grams)</th>
                  <th>Medium rate (grams)</th>
                  <th>High rate (grams)</th>
                </tr>
              </thead>
              <tbody>
                {state.landscapeBedContainerRates.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.label}</td>
                      <td>{item.surfArea}</td>
                      <td>{item.low}</td>
                      <td>{item.medium}</td>
                      <td>{item.high}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="table-fade"></div>
          </div>
         </>
        )}
      </div>
      </div>
    )
}

export default PageContentFindRates
