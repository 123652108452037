import React, { useState, useEffect, useContext, useRef } from "react"
import { ButtonGroup, ToggleButton } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { appContext } from '../../provider'

const LongevityRadios = (props) => {

  const context = useContext(appContext)

  const [radioValue, setRadioValue] = useState(context?.longevity || null)
  
  const toggleButtonRef = useRef(null);

  const handleChange = (e) => {
    setRadioValue(e.currentTarget.value)
    props.onHandleRadioChange && props.onHandleRadioChange(e.currentTarget.value);
  }

  useEffect(() => {
    
    toggleButtonRef.current.required = true
  }, [radioValue, toggleButtonRef])

  const data = useStaticQuery(graphql`
    query {
      wp {
        longevityRanges(where: { orderby: TERM_ORDER }) {
          nodes {
            databaseId
            name
            slug
            productCategoryFields {
              icon {
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 100, maxHeight: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="form-group">
      <p className="mb-2 font-weight-bold text-secondary">Select longevity</p>
      <ButtonGroup toggle>
        {data.wp.longevityRanges.nodes.map((radio, i) => {
          // const image = data[queryAlias]?.childImageSharp?.fluid
          const image =
            radio.productCategoryFields?.icon?.imageFile?.childImageSharp?.fluid
          return (
            <ToggleButton
              key={i}
              type="radio"
              variant="longevity"
              name="radio"
              value={radio.slug}
              checked={radioValue === radio.slug}
              onChange={e => handleChange(e)}
              onClick={props.onRadioChange}
              className={`btn-sm`}
              inputRef={toggleButtonRef}
            >
              {image && <Img fluid={image} loading="eager" />}
              {radio.name}
            </ToggleButton>
          )
        })}
      </ButtonGroup>
    </div>
  )
}

export default LongevityRadios
