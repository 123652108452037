import React, { useContext, useState, useEffect } from 'react'
import { appContext } from '../../provider'
import LongevityRadios from "./CustomLongevityRadios"
import GrowingEnvRadios from "./CustomGrowingEnvRadios"
import ListAllProducts from './ListAllProducts'

const PageContentFindProduct = (props) => {
    // const { page } = props
    // const [filterGrowingEnvironment, setFilterGrowinEnvironment] = useState("")
    // const [filterLongevityRanges, setfilterLongevityRanges] = useState("")
    
    const context = useContext(appContext)

    const [combinedFilters, setCombinedFilters] = useState([])

    const handleFormSubmit = (e) => {
        e && e.preventDefault()
        setCombinedFilters([context.growingEnv, context.longevity])
    }

    useEffect(() => {
        handleFormSubmit()
    }, [])

    return(
        <>
        <div id="search" className="anchor-offset"></div>
        <div className="container mb-3">
            <form onSubmit={handleFormSubmit}>
                <GrowingEnvRadios onHandleRadioChange={(e) => { context.updateGrowingEnv(e)}} />
                <LongevityRadios onHandleRadioChange={(e) => { context.updateLongevity(e) }} />
                <button type="submit" className="btn btn-icl btn-block btn-skew"><span>Search</span></button>
            </form>
        </div>
        <div className="results">
            <div className="container">
                <ListAllProducts filter={combinedFilters} columns={1}/>
            </div>
        </div>
        </>
    )
}

export default PageContentFindProduct